import {ModalWithAlert, useAlert} from "../../../alerting";
import {useTranslation} from "react-i18next";
import {FC, useState} from "react";
import {OnCompleteCallback} from "../../../utils";
import {CheckBox} from "@interstate/components/CheckBox";
import {useLazyQuery, useMutation} from "@apollo/client";
import {
  EntityResponse,
  LookupOperationalEntity,
  SetEnterpriseEntitlementEnabled
} from "../../backend";
import {
  EnterpriseEntitlement,
  OperationalEntity,
  UpdateResponse
} from "@common-core/coat-operational-hierarchy-appsync-model";
import {Endpoints} from "../../../runtime";
import {assertEntityPresent} from "../../boids/transforms";
import {useOperationalEntity} from "../../context";

export const EditEnterpriseEntitlementsModal: FC<{
  id: string;
  initialEnterpriseEntitlements: EnterpriseEntitlement[];
  onComplete: OnCompleteCallback;
}> = ({id, initialEnterpriseEntitlements, onComplete}) => {
  const {t} = useTranslation();
  const {alert, clearAlert, createErrorAlert} = useAlert({
    errorTitleKey: "toast.backend-error"
  });
  const [working, setWorking] = useState(false);
  const [enterpriseEntitlements, setEnterpriseEntitlements] = useState(
    initialEnterpriseEntitlements
  );
  const {entity, setEntity} = useOperationalEntity();

  const [lookUpOperationalEntity] = useLazyQuery<
    EntityResponse<OperationalEntity>
  >(LookupOperationalEntity, {
    context: {
      endpoint: Endpoints.APPSYNC
    },
    fetchPolicy: "network-only"
  });

  const [setEnterpriseEntitlementEnabled] = useMutation<UpdateResponse>(
    SetEnterpriseEntitlementEnabled,
    {
      context: {
        endpoint: Endpoints.APPSYNC
      }
    }
  );

  const refreshEntity = async (): Promise<void> => {
    return lookUpOperationalEntity({variables: {id: entity.id}})
      .then(assertEntityPresent)
      .then(setEntity);
  };

  const enterpriseEntitlementChanged = async (event: any): Promise<void> => {
    const changedEnterpriseEntitlementIndex = enterpriseEntitlements.findIndex(
      enterpriseEntitlement =>
        enterpriseEntitlement.enterpriseEntitlementId ===
        event.target.value.checkboxValue
    );
    setEnterpriseEntitlements([
      ...enterpriseEntitlements.slice(0, changedEnterpriseEntitlementIndex),
      {
        ...enterpriseEntitlements[changedEnterpriseEntitlementIndex],
        enabled: event.target.value.checked
      },
      ...enterpriseEntitlements.slice(changedEnterpriseEntitlementIndex + 1)
    ]);
  };

  const setEnterpriseEntitlementEnabledValues = async (
    enterpriseEntitlements: EnterpriseEntitlement[]
  ) => {
    for (const [
      index,
      enterpriseEntitlementInput
    ] of enterpriseEntitlements.entries()) {
      // Only update the enabled status if it has changed
      if (
        enterpriseEntitlementInput.enabled !==
        initialEnterpriseEntitlements[index].enabled
      ) {
        const enterpriseEntitlement = {
          enterpriseEntitlementId:
            enterpriseEntitlementInput.enterpriseEntitlementId,
          commonOrgId: id,
          enabled: enterpriseEntitlementInput.enabled
        };
        await setEnterpriseEntitlementEnabled({
          variables: {
            enterpriseEntitlement
          }
        });
      }
    }
  };

  const saveChanges = async () => {
    setWorking(true);
    clearAlert();
    setEnterpriseEntitlementEnabledValues(enterpriseEntitlements)
      .then(refreshEntity)
      .then(() => onComplete(true))
      .catch(createErrorAlert)
      .finally(() => setWorking(false));
  };

  const cancelChanges = () => {
    onComplete(true);
  };

  return (
    <ModalWithAlert
      id={"edit-enterprise-entitlements-modal"}
      data-testid={"edit-enterprise-entitlements-modal"}
      show={true}
      size={"large"}
      header={t("modal.header.edit-enterprise-entitlements")}
      alert={alert}
      footer={{
        primary: {
          action: saveChanges,
          label: t("common-actions.save-changes"),
          isLoading: working
        },
        options: {
          action: cancelChanges,
          label: t("common-actions.cancel"),
          isLoading: working,
          buttonStyle: "tertiary"
        }
      }}
      onHide={cancelChanges}>
      {enterpriseEntitlements.map(enterpriseEntitlement => (
        <CheckBox
          key={enterpriseEntitlement.enterpriseEntitlementId}
          id={`checkbox-${enterpriseEntitlement.enterpriseEntitlementId}`}
          data-testid={`checkbox-${enterpriseEntitlement.enterpriseEntitlementId}`}
          value={enterpriseEntitlement.enterpriseEntitlementId}
          onChange={enterpriseEntitlementChanged}
          label={enterpriseEntitlement.enterpriseEntitlementName}
          checked={enterpriseEntitlement.enabled}
        />
      ))}
    </ModalWithAlert>
  );
};
